<template>
    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-fluid">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <i class="fas fa-edit"></i>
                                </div>
                                Crear EPS
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-fluid mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4 offset-4">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Codigo</label>
                                        <input
                                            type="text"
                                            :class="{'is-invalid' : $v.eps.IDEPS.$error}"
                                            class="form-control form-control-sm"
                                            v-model="$v.eps.IDEPS.$model"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.eps.IDEPS.required">Este Campo es Requerido</span>
                                        <span class="invalid-feedback" v-if="!$v.eps.IDEPS.isUnique">El Codigo de la Eps ya se encuentra registrado</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>NIT</label>
                                        <input
                                            type="text"
                                            :class="{'is-invalid' : $v.eps.NIT.$error}"
                                            class="form-control form-control-sm"
                                            v-model="eps.NIT"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.eps.NIT.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            v-model="eps.DESEPS"
                                            :class="{'is-invalid' : $v.eps.DESEPS.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.eps.DESEPS.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Municipio</label>
                                        <v-select-municipio v-model="eps.MUNICIPIO"
                                        :validator="$v.eps.MUNICIPIO"
                                        ></v-select-municipio>
                                        <span class="invalid-feedback" v-if="!$v.eps.MUNICIPIO.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Direccion</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            :class="{'is-invalid' : $v.eps.DIRECCION.$error}"
                                            v-model="eps.DIRECCION"/>
                                        <span class="invalid-feedback" v-if="!$v.eps.DIRECCION.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Telefono</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            v-model="eps.TELEFONO"
                                            :class="{'is-invalid' : $v.eps.TELEFONO.$error }"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.eps.TELEFONO.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Correo Electronico</label>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            v-model="eps.email"
                                            :class="{'is-invalid' : $v.eps.email.$error}"
                                        />
                                        <span class="invalid-feedback" v-if="!$v.eps.email.required">Este Campo es Requerido</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <button
                                            type="button"
                                            @click="save()"
                                            class="btn btn-success btn-sm btn-block shadow-sm"
                                        >
                                            <i class="fas fa-save"></i> Guardar Cambios
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import EpsService from "../../services/epsService";
import VSelectMunicipio from "../../components/common/vSelectMunicipio";
import Swal from 'sweetalert2/src/sweetalert2';

export default {
    components: {VSelectMunicipio},
    data() {
        return {
            eps: {
                IDEPS : "",
                NIT : "",
                DESEPS : "",
                MUNICIPIO : "",
                DIRECCION : "",
                TELEFONO : "",
                email : "",
            },
        };
    },
    validations : {
        eps: {
            IDEPS : {
                required,
                isUnique : async function(value){
                    if(value === "") return true;
                    const response = ( await EpsService.isUnique(value))?.data;
                    return Boolean(response);
                }
            },
            NIT : {required},
            DESEPS : {required},
            MUNICIPIO : {required},
            DIRECCION : {required},
            TELEFONO : {required},
            email : {required},
        },
    },
    methods: {
        async save() {
            try{

                this.$v.eps.$touch();

                if(this.$v.eps.$invalid) return;

                this.LoaderSpinnerShow();

                await EpsService.store(this.eps);

                this.LoaderSpinnerHide();

                Swal.fire("Eps registrada de forma exitosa", '', 'success');

            }catch (e){
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ha ocurrido un error al procesar la peticion', '', 'error');
            }
        }
    },
};
</script>
